import { FC, MouseEventHandler, useState } from 'react'
import { Col, Row, Switch, Popconfirm } from 'antd'
import { LockOutlined, CloseOutlined } from '@ant-design/icons'
import GButton from './gappify/GButton'

import type { SwitchChangeEventHandler } from 'antd/lib/switch'
import useRolesAndPermissions from '../hooks/useRolesAndPermissions'
import useFeatureFlagsStore from '../global/useFeatureFlagsStore'

interface TransactionPanelProps {
  countSelected?: number
  canEditTable: boolean
  onEditSwitch: (checked: boolean) => void
  onClickCreateQje?: MouseEventHandler<HTMLElement>
  isCreateQjeEnable?: boolean
  showSubmitCancel: boolean
  onClickCancel: () => void
  onClickSubmit: () => void
  onNoQjeClick?: MouseEventHandler<HTMLElement>
  selectedRowsCount?: number
  editedRowsCount?: number
  editedRows?: Record<string, any>
  showVoidActionBtn?: boolean
  isVoidActionBtnDisabled?: boolean
  onClickVoid?: MouseEventHandler<HTMLElement>
}

const TransactionPanel: FC<TransactionPanelProps> = ({
  countSelected,
  canEditTable,
  onEditSwitch,
  onClickCreateQje,
  isCreateQjeEnable,
  showSubmitCancel,
  onClickCancel,
  onClickSubmit,
  onNoQjeClick,
  selectedRowsCount,
  editedRowsCount,
  editedRows,
  showVoidActionBtn,
  isVoidActionBtnDisabled,
  onClickVoid
}): JSX.Element => {
  const [canEdit, setCanEdit] = useState<boolean>(false)
  const RP = useRolesAndPermissions()
  const dublin = useFeatureFlagsStore((state) => state.dublin)

  const handleOnChangeSwitch: SwitchChangeEventHandler = (
    checked: boolean,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setCanEdit(checked)
    onEditSwitch(checked)
  }

  return (
    <div
      className='txn-panel-container'
      data-testid='txn-panel'
      data-cy='txn-panel'
    >
      <Row>
        <Col sm={24} lg={6} className='txn-panel-left'>
          {showVoidActionBtn && (
            <GButton
              dataTestId='txn-panel-void-btn'
              dataCy='txn-panel-void-btn'
              btnText='Void'
              shape='default'
              className='g-btn-default'
              type='primary'
              onClick={onClickVoid}
              disabled={isVoidActionBtnDisabled}
            />
          )}
        </Col>
        <Col sm={24} lg={12} className='txn-panel-center'>
          {canEdit ? (
            <span
              data-testid='txn-panel-edited-rows-count'
              data-cy='txn-panel-edited-rows-count'
            >
              {' '}
              {editedRowsCount} item/s edited
            </span>
          ) : (
            <span
              data-testid='txn-panel-selected-rows-count'
              data-cy='txn-panel-selected-rows-count'
            >
              {' '}
              {countSelected} item/s selected
            </span>
          )}
          {editedRowsCount !== undefined && editedRowsCount > 0 && canEdit && (
            <>
              <Popconfirm
                title={
                  // Check if DUBLIN and one of the editedRows has g_status of 'sent' or 'draft'
                  // refer to TxnLinesGSTatus.ts for complete enums
                  // SENT === 1
                  // DRAFT === 2
                  dublin &&
                  editedRows?.some((row: Record<string, any>) =>
                    ['1', '2'].includes(`${row.g_status}`)
                  ) ? (
                    <div style={{ width: 300 }}>
                      You have edited a line with ‘Sent’ or ‘Draft’ status.
                      Saving your changes will prevent you from receiving a
                      response related to the line. Are you sure you want to
                      proceed?
                    </div>
                  ) : (
                    'Are you sure you want to save?'
                  )
                }
                okText='Yes'
                cancelText='No'
                onConfirm={onClickSubmit}
                placement='left'
              >
                <GButton btnText='Save' shape='default' type='primary' />
              </Popconfirm>
              <Popconfirm
                title='Are you sure you want to cancel?'
                okText='Yes'
                cancelText='No'
                onConfirm={onClickCancel}
                placement='left'
              >
                <GButton btnText='Cancel' shape='default' />
              </Popconfirm>
            </>
          )}
        </Col>
        <Col sm={24} lg={6} className='txn-panel-right'>
          <span>Edit</span>
          <Switch
            data-testid='txn-panel-edit-switch'
            data-cy='txn-panel-edit-switch'
            defaultChecked={canEdit}
            onChange={handleOnChangeSwitch}
            disabled={
              editedRowsCount !== undefined && editedRowsCount > 0 && canEdit
            }
          />
          <GButton
            dataTestId='txn-panel-create-qje-btn'
            dataCy='txn-panel-create-qje-btn'
            shape='default'
            className='g-btn-default'
            btnText='Create QJE'
            icon={
              <LockOutlined
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
            }
            onClick={onClickCreateQje}
            disabled={!RP.USER_HAS_CREATE_QJE_PERMISSION || canEdit}
          />
          <GButton
            dataTestId='txn-panel-no-qje-btn'
            dataCy='txn-panel-no-qje-btn'
            btnText='No QJE'
            icon={
              <CloseOutlined
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              />
            }
            shape='default'
            className='g-btn-default'
            disabled={canEdit || !RP.USER_HAS_NO_QJE_PERMISSION}
            onClick={onNoQjeClick}
          />
        </Col>
      </Row>
    </div>
  )
}

export default TransactionPanel
