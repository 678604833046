import { InfoCircleFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'

type Props = {
  message: string
}

const TooltipIcon = (props: Props) => {
  const { message } = props

  return (
    <Tooltip title={message}>
      <InfoCircleFilled
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
        style={{ marginLeft: 8, color: '#715BB4' }}
      />
    </Tooltip>
  )
}

export default TooltipIcon
