import Flag from 'src/features/Flag'
import { FEATURE_FLAGS } from 'src/features/Flag/constants'
import TaskManagerTable from '../TaskManagerTable'
import TaskManagerTableQ3 from '../TaskManagerTableQ3'

const AccrualManagerWrapper = () => {
  /**
   * TODO: Implement the following:
   * 1. Implement the feature flag + loading state
   */
  return (
    <Flag
      authorizedFlags={[FEATURE_FLAGS.dublin]}
      renderOn={<TaskManagerTableQ3 />}
      renderOff={<TaskManagerTable />}
    />
  )
}

export default AccrualManagerWrapper
