// ** Imports **
import { DatePicker } from 'antd'
import { Moment } from 'moment'
import { getCurrentPeriod } from '../../../utils/Date'

// ** Utility functions **
const mergeSimilarRows = (rowsData: any) => {
  return rowsData
}

const getTxnLineId = (txn_number: string) => {
  const toArray = txn_number?.split('-')
  const indexOfTxnLineId = 3
  return toArray && toArray[indexOfTxnLineId]
}

const isValidUrlWithoutHttps = (url: string) => {
  const regex = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}$', // domain name
    'i' // case-insensitive
  )
  return regex.test(url)
}

const isValidURL = (text: string) => {
  try {
    new URL(text)
    return true
  } catch {
    return false
  }
}

// Maximum records per page constant (used for QJE custom pager component)
const maxRecordsPerPage = Math.pow(2, 31) - 1

// Function to calculate display text width
const displayTextWidth = (text: string) => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (context) {
    context.font = '13px Open Sans'
    return context!.measureText(text).width
  }
  return 500
}

const getTxnMonthsOptions = ({
  onChangeDatePicker
}: {
  onChangeDatePicker:
    | ((value: Moment | null, dateString: string) => void)
    | undefined
}) => {
  const start = getCurrentPeriod()
  let mos = []

  for (let i = 0; i < 6; i++) {
    let period = start.format('MMMM YYYY')
    let key = start.startOf('month')

    mos.push({
      label: period,
      key: key.format('YYYY-MM-DD')
    })
    start.subtract(1, 'month')
  }
  mos.push({
    type: 'divider'
  })
  mos.push({
    label: (
      <DatePicker
        className='period-picker'
        picker='month'
        placeholder='Select Period'
        format='MMMM YYYY'
        onChange={onChangeDatePicker}
      />
    ),
    key: 'select'
  })

  return mos
}

const getExportMenu = () => {
  let menu: Record<string, any>[] = [
    {
      label: 'CSV',
      key: 'csv'
    },
    {
      label: 'PDF',
      key: 'pdf'
    },
    {
      label: 'Excel',
      key: 'excel'
    }
  ]
  return menu
}

const getFilterMenu = ({
  filtersObject,
  enableCreateQje
}: Record<string | number, any>) => {
  return [
    ...Object.keys(filtersObject)
      .filter(
        (key: any) =>
          !['new', 'all', 'unposted', 'posted', 'rejected'].includes(key)
      )
      .map((key: any) => {
        return { key: key, label: filtersObject[key] }
      })
      .sort((a: any, b: any) => (a.label > b.label ? 1 : -1)),
    {
      type: 'divider'
    },
    {
      key: 'all',
      label: 'All JEs'
    },
    {
      key: 'unposted',
      label: 'Unposted JEs'
    },
    {
      key: 'posted',
      label: 'Posted JEs'
    },
    {
      key: 'rejected',
      label: 'Rejected JEs'
    },
    {
      type: 'divider'
    },
    {
      key: 'new',
      label: 'Create New Filter',
      disabled: !enableCreateQje
    }
  ]
}

//Manually getting the subtype based on tabs
const getSubType = (tab: string) => {
  if (tab === 'review-je') {
    return 'unposted'
  } else if (tab === 'unreviewed-po-closure') {
    return 'unreviewed'
  } else {
    return 'reviewed'
  }
}

//Manually getting the type based on tabs
const getType = (tab: string) => {
  if (tab === 'review-je') {
    return 'JE'
  } else if (tab === 'unreviewed-po-closure') {
    return 'PO'
  } else {
    return 'PO'
  }
}

const getDefaultCyAttributes = ({
  apiName,
  recordId
}: {
  apiName: string
  recordId: string | number
}) => {
  return {
    'data-testid': `qje-${apiName}-${recordId}`,
    'data-cy': `qje-${apiName}-${recordId}`
  }
}

// ** Exports **
export {
  mergeSimilarRows,
  getTxnLineId,
  isValidUrlWithoutHttps,
  isValidURL,
  maxRecordsPerPage,
  displayTextWidth,
  getTxnMonthsOptions,
  getExportMenu,
  getFilterMenu,
  getSubType,
  getType,
  getDefaultCyAttributes
}
