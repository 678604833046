// ** React Components and Hooks **
import { FC, useEffect, useState } from 'react'
import type { AxiosError } from 'axios'

// ** React query imports **
import useQje from '../hooks/useQje'
import download from 'downloadjs'

// ** Ant Design Components **
import { message, Modal, Space } from 'antd'
import {
  ExclamationCircleOutlined,
  DownOutlined,
  ExportOutlined
} from '@ant-design/icons'

// ** Custom Components **
import GButton from '../../../components/gappify/GButton'
import RejectJEModal from './RejectJeModal'
import GDropdownBtn from '../../../components/gappify/GDropdownBtn'
import FilterComponents from './FilterComponents'
import PostJeModalWrapper from './PostJeModalWrapper'

// ** Hooks **
import useRolesAndPermissions from '../../../hooks/useRolesAndPermissions'

// ** Zustand Store **
import useQjeStore from '../manager/qje-store'

// ** Third party libraries **
import moment from 'moment'

// ** Utils **
import { getCurrentPeriod } from '../../../utils/Date'
import {
  getExportMenu,
  getTxnMonthsOptions,
  maxRecordsPerPage,
  getSubType,
  getType
} from '../utils/qje-utils'

import { qjeExport } from '../../../services/QjeManagerApi'

// ** Types **
import type QjeRowDropDownButtonsProps from '../types/QjeRowDropDownButtonsProps'

// =======================================================================
const ActionButtonsRow: FC<QjeRowDropDownButtonsProps> = (
  props: QjeRowDropDownButtonsProps
): JSX.Element => {
  // ** States **
  const [showPeriodOptions, setShowPeriodOptions] = useState(false)
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false)

  // ** Hooks **
  const RP = useRolesAndPermissions()

  // ** React Query **
  const { handleSubmit, isSuccessQjeAction, isErrorQjeAction } = useQje()

  // ** Zustand Store **
  const {
    selectedLineGroups,
    tab,
    period,
    setPeriod,
    filter,
    sortField,
    sortDir,
    setPage,
    previewMode,
    filter_fields
  } = useQjeStore()

  // ** Functions **
  const handleOnClickTxnPeriod = (item: any) => {
    if (item.key !== 'select') {
      setPeriod({
        key: item.key,
        label: moment(item.key, 'YYYY-MM-DD').format('MMMM YYYY')
      })
      setPage(1)
      setShowPeriodOptions(false)
    }
  }

  //use the old way of handling export button
  // TODO: make it react query
  const handleClickExport = async (e: { key: string }) => {
    setIsLoadingExport(true)
    try {
      const parameters = {
        // Update parameters when in preview mode
        previewMode: previewMode,
        ...(!previewMode ? { filter } : { filter_fields: filter_fields }),
        period: period.key,
        subtype: getSubType(tab),
        type: getType(tab),
        sortField: sortField,
        sortDir: sortDir,
        recordsPerPage: maxRecordsPerPage
      }
      const { data, headers } = await qjeExport(e.key, parameters)
      const content = headers['content-type']

      if (e.key === 'excel') {
        download(data, `QJE-${period.key}-JE.xlsx`, content)
      } else {
        download(data, `QJE-${period.key}-JE.${e.key}`, content)
      }
    } catch (error) {
      const { response } = error as AxiosError
      console.log(response)
    } finally {
      setIsLoadingExport(false)
    }
  }

  // Handle success POST method...
  useEffect(() => {
    if (isSuccessQjeAction) {
      // Handle success, e.g., show a success message, update UI, etc.
      console.log('Mutation was successful!')
      message.success("PO's marked for closure.")
    }
  }, [isSuccessQjeAction])

  // Catch error when POST method failed..
  useEffect(() => {
    if (isErrorQjeAction) {
      // Handle error, e.g., show an error message, log the error, etc.
      console.error('An error occurred during mutation!')
    }
  }, [isErrorQjeAction])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <Space size={5}>
        <GDropdownBtn
          dataTestId='global-filter-panel-period-dropdown-btn'
          dataCy='global-filter-panel-period-dropdown-btn'
          menuItems={getTxnMonthsOptions({
            onChangeDatePicker: (e) => {
              setPeriod({
                key: moment(e).format('YYYY-MM-DD'),
                label: moment(e).format('MMMM YYYY')
              })
              setShowPeriodOptions(false)
            }
          })}
          btnText={period.label}
          children={
            <DownOutlined
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
          }
          onClick={handleOnClickTxnPeriod}
          defaultSelectedKeys={[
            getCurrentPeriod().startOf('month').format('YYYY-MM-DD')
          ]}
          visible={showPeriodOptions}
          onVisibleChange={(open: boolean) => {
            setShowPeriodOptions(open)
          }}
        />
        {/* Only show this component under Review JE tab */}
        {tab === 'review-je' && (
          <>
            <FilterComponents />
          </>
        )}
      </Space>
      <Space size={5}>
        {/* If inside review-je, only display Post JE and Reject JE */}
        {/* If inside unreviewed-po-closure, only display Close PO */}
        {/* Else, no button will be displayed in the right side */}
        {tab === 'review-je' ? (
          <>
            <PostJeModalWrapper />
            {/* <PostJEModal /> */}
            <RejectJEModal />
          </>
        ) : tab === 'unreviewed-po-closure' ? (
          <GButton
            dataTestId='qje-btn-close-po'
            dataCy='qje-btn-close-po'
            btnText={'Close PO'}
            disabled={
              !RP.USER_HAS_CLOSE_PO_QJE_PERMISSION ||
              selectedLineGroups?.length === 0
            }
            onClick={() =>
              Modal.confirm({
                title: 'Are you sure you want to close these items?',
                icon: (
                  <ExclamationCircleOutlined
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                  />
                ),
                centered: true,
                okText: 'Yes',
                cancelText: 'No',
                onOk: async () => {
                  // ** NEW IMPLEMENTATION (using react-query) **
                  await handleSubmit({
                    payload: {
                      line_groups: Array.from(new Set(selectedLineGroups))
                    },
                    endpoint: '/api/review/po'
                  })
                  Modal.destroyAll()
                }
              })
            }
          />
        ) : null}
        <GDropdownBtn
          loading={isLoadingExport}
          dataTestId='global-filter-panel-export-dropdown-btn'
          dataCy='global-filter-panel-export-dropdown-btn'
          menuItems={getExportMenu()}
          btnText='Export to'
          btnIcon={
            <ExportOutlined
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
          }
          children={
            <DownOutlined
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            />
          }
          disabled={!RP.USER_HAS_EXPORT_QJE_PERMISSION}
          className='g-btn-default'
          onClick={handleClickExport}
        />
      </Space>
    </div>
  )
}

export default ActionButtonsRow
