// ** React Query **
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

// ** Custom Hooks **
import useApiService from '../../../hooks/useApiService'

// ** API Services Imports **
import useQjeService from '../services/QjeService'

// ** Zustand Imports **
import useQjeStore from '../manager/qje-store'

// ** AntD Imports **
import { message } from 'antd'

// =================================================================
export default function useQje() {
  // ** API Services **
  const { QjeAPI } = useQjeService()

  // ** Hooks **
  const { msUrl } = useApiService()

  // ** TanStack Query **
  const queryClient = useQueryClient()

  // ** Local Storage **
  // const user = JSON.parse(localStorage.getItem('rolesAndPermissions') as string)

  // ** Store **
  const {
    filter,
    filter_fields,
    page,
    period,
    previewMode,
    recordsPerPage,
    sortField,
    sortDir,
    tab,
    setSelectedLineGroups,
    exportFormat,
    focusedField,
    searchTerm
  } = useQjeStore()

  // ** GET METHODS **
  const getQjeSettings = useQuery({
    queryKey: ['qje-settings', tab],
    queryFn: () =>
      QjeAPI({
        params: {
          qjetype: tab === 'review-je' ? 'JE' : 'PO',
          subtype:
            tab === 'review-je'
              ? 'unposted'
              : tab === 'unreviewed-po-closure'
              ? 'unreviewed'
              : 'reviewed',
          period: period.key
        },
        options: {
          baseURL: msUrl.qje_manager,
          endpoint: '/api/qje/filter/getSelectedFields',
          method: 'GET',
          customHeaders: {
            'User-Token': `${localStorage?.getItem('authToken')}`
          }
        }
      })
  })

  const getReviewJe = useQuery({
    queryKey: [
      'review-je',
      filter,
      filter_fields,
      page,
      period,
      recordsPerPage,
      sortField,
      sortDir
    ],
    queryFn: () =>
      QjeAPI({
        params: {
          // Pass filter when not in preview mode, else
          // Pass filter_fields when in preview mode
          ...(!previewMode ? { filter } : { filter_fields }),
          page: page,
          period: period.key,
          previewMode: previewMode,
          subtype: 'unposted',
          type: 'JE',
          recordsPerPage: recordsPerPage,
          sortField: sortField,
          sortDir: sortDir
        },
        options: {
          baseURL: msUrl.qje_manager,
          endpoint: '/api/transactions',
          method: 'GET',
          customHeaders: {
            'User-Token': `${localStorage?.getItem('authToken')}`
          }
        }
      }),
    enabled: tab === 'review-je'
  })

  const getUnfilteredReviewJe = useQuery({
    queryKey: [
      'unfiltered-review-je',
      page,
      period,
      recordsPerPage,
      sortField,
      sortDir
    ],
    queryFn: () =>
      QjeAPI({
        params: {
          filter: 'all',
          page: page,
          period: period.key,
          previewMode: previewMode,
          subtype: 'unposted',
          type: 'JE',
          recordsPerPage: recordsPerPage,
          sortField: sortField,
          sortDir: sortDir
        },
        options: {
          baseURL: msUrl.qje_manager,
          endpoint: '/api/transactions',
          method: 'GET',
          customHeaders: {
            'User-Token': `${localStorage?.getItem('authToken')}`
          }
        }
      }),
    enabled: tab === 'review-je'
  })

  const getUnreviewedPoClosure = useQuery({
    queryKey: [
      'unreviewed-po-closure',
      filter,
      page,
      period,
      recordsPerPage,
      sortField,
      sortDir
    ],
    queryFn: () =>
      QjeAPI({
        params: {
          filter: 'all',
          page: page,
          period: period.key,
          previewMode: false,
          subtype: 'unreviewed',
          type: 'PO',
          recordsPerPage: recordsPerPage,
          sortField: sortField,
          sortDir: sortDir
        },
        options: {
          baseURL: msUrl.qje_manager,
          endpoint: '/api/transactions',
          method: 'GET',
          customHeaders: {
            'User-Token': `${localStorage?.getItem('authToken')}`
          }
        }
      }),
    enabled: tab === 'unreviewed-po-closure'
  })

  const getReviewedPoClosure = useQuery({
    queryKey: [
      'reviewed-po-closure',
      filter,
      page,
      period,
      recordsPerPage,
      sortField,
      sortDir
    ],
    queryFn: () =>
      QjeAPI({
        params: {
          filter: 'all',
          page: page,
          period: period.key,
          previewMode: false,
          subtype: 'reviewed',
          type: 'PO',
          recordsPerPage: recordsPerPage,
          sortField: sortField,
          sortDir: sortDir
        },
        options: {
          baseURL: msUrl.qje_manager,
          endpoint: '/api/transactions',
          method: 'GET',
          customHeaders: {
            'User-Token': `${localStorage?.getItem('authToken')}`
          }
        }
      }),
    enabled: tab === 'reviewed-po-closure'
  })

  const exportQuery = useQuery({
    queryKey: ['qje-export', exportFormat],
    queryFn: () => {
      if (exportFormat !== undefined)
        return QjeAPI({
          params: {
            filter: filter,
            period: period.key,
            subtype:
              tab === 'review-je'
                ? 'unposted'
                : tab === 'unreviewed-po-closure'
                ? 'unreviewed'
                : 'reviewed',
            type: tab === 'review-je' ? 'JE' : 'PO',
            recordsPerPage: recordsPerPage,
            sortField: sortField,
            sortDir: sortDir
          },
          options: {
            baseURL: msUrl.qje_manager,
            endpoint: `api/qje/export/${exportFormat}`,
            method: 'GET',
            responseType: ['pdf', 'excel'].includes(exportFormat)
              ? 'arraybuffer'
              : undefined
          }
        })
      else return null
    }
  })

  const fetchSuggestedValues = useQuery({
    queryKey: ['fetch-suggestested-values'],
    queryFn: () => {
      if (focusedField !== undefined)
        return QjeAPI({
          params: { term: searchTerm },
          options: {
            baseURL: msUrl.qje_manager,
            endpoint: `/api/options/${focusedField}`,
            method: 'GET'
          }
        })
      else return null
    },
    enabled: false
  })

  // ** POST METHODS **
  const {
    mutate: qjeAction,
    isLoading: isLoadingQjeAction,
    isSuccess: isSuccessQjeAction,
    isError: isErrorQjeAction
  } = useMutation(QjeAPI, {
    onSuccess: (response) => {
      queryClient.invalidateQueries([
        tab,
        filter,
        page,
        period,
        recordsPerPage,
        sortField,
        sortDir
      ])

      // ** After succesfull API call, do something... **
      // message.success('All changes have been applied.')
      // console.log('POST method success', response)
      setSelectedLineGroups([])
    },
    onError: ({ data }) => {
      console.log('POST method failed', data)
      message.error('An error occurred.')
    }
  })

  const handleSubmit = ({
    payload,
    endpoint
  }: {
    payload: Record<string, any>
    endpoint: string
  }) => {
    qjeAction({
      data: payload || undefined,
      options: {
        baseURL: msUrl.qje_manager,
        endpoint: endpoint,
        method: 'POST'

        // ** Usage of customHeaders **
        // customHeaders: {
        //   'User-Token': `${localStorage?.getItem('authToken')}`
        // }
      }
    })
  }

  return {
    getQjeSettings,
    getReviewJe,
    getUnreviewedPoClosure,
    getReviewedPoClosure,
    isLoadingQjeAction,
    handleSubmit,
    isSuccessQjeAction,
    isErrorQjeAction,
    exportQuery,
    fetchSuggestedValues,
    getUnfilteredReviewJe
  }
}
