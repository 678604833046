// ** React imports **
import { useState } from 'react'

// ** AntD imports **
import { CopyOutlined } from '@ant-design/icons'
import { Alert, Button, Card, Modal } from 'antd'

// ** Custom Components **
import TransactionLine from 'src/types/TransactionLine'

type ModalProps = {
  isModalOpen?: boolean
  handleOk?: () => void
  handleCancel?: () => void
  transactionLine?: TransactionLine | Record<string, any> | null
}

const CopyAccrualFormLinkModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  transactionLine
}: ModalProps) => {
  // ** States **
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)

  const copyToClipboard = (text: string) => {
    if (navigator.clipboard && window.isSecureContext) {
      // Navigator clipboard API method
      navigator.clipboard
        .writeText(text)
        .then(function () {
          setShowSuccess(true)
        })
        .catch(function (error) {
          setShowError(false)
        })
    } else {
      // Fallback method using an off-screen textarea
      let textArea = document.createElement('textarea')
      textArea.value = text
      // Avoid scrolling to bottom
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      try {
        document.execCommand('copy')
        setShowSuccess(true)
      } catch (err) {
        setShowError(false)
      }
      document.body.removeChild(textArea)
    }
  }

  return (
    <Modal
      data-testid='copy-accrual-form-link-modal'
      data-cy='copy-accrual-form-link-modal'
      title='Confirmation'
      open={isModalOpen}
      onOk={handleOk}
      onCancel={() => {
        handleCancel && handleCancel()
        setShowSuccess(false)
        setShowError(false)
      }}
      footer={null}
    >
      <p
        style={{ color: '#777' }}
        data-testid='modal-message'
        data-cy='modal-message'
      >
        Are you copying the accrual form link for{' '}
        <strong>{transactionLine?.g_name}</strong>? Click the copy button to
        copy the URL, or highlight and copy the text.
      </p>

      {showSuccess && (
        <Alert
          style={{ marginBottom: '1em', borderRadius: 10 }}
          data-testid='success-message'
          data-cy='success-message'
          message='Your link has been successfully copied to the clipboard.'
          type='success'
          showIcon
        />
      )}

      {showError && (
        <Alert
          style={{ marginBottom: '1em', borderRadius: 10 }}
          data-testid='error-message'
          data-cy='error-message'
          message='An error occurred while trying to copy the link to the clipboard. Please try again.'
          type='error'
          showIcon
        />
      )}

      {/* Internal Accrual */}
      {
        // TODO: update this to internal_url (BE dependency)
        transactionLine?.line_properties?.actions?.form_link?.url && (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <span>Internal Accrual</span>
              <Button
                data-testid='internal-copy-accrual-link-btn'
                data-cy='internal-copy-accrual-link-btn'
                type='link'
                icon={
                  <CopyOutlined
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                }
                onClick={() => {
                  copyToClipboard(
                    // TODO: update this to internal_url (BE dependency)
                    transactionLine?.line_properties?.actions?.form_link
                      ?.url as string
                  )
                }}
                style={{ color: '#725bb4' }}
              >
                Copy
              </Button>
            </div>
            <Card
              data-testid='internal-form-link-card'
              data-cy='internal-form-link-card'
              style={{
                marginBottom: '1em',
                borderRadius: 12
              }}
            >
              {/* TODO: update this to internal_url (BE dependency) */}
              {
                transactionLine?.line_properties?.actions?.form_link
                  ?.url as string
              }
            </Card>
          </>
        )
      }

      {/* Vendor Accrual */}
      {/* TODO: update this to vdac_url (BE dependency) */}
      {transactionLine?.line_properties?.actions?.form_link?.url && (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <span>Vendor Accrual</span>
            <Button
              data-testid='vdac-copy-accrual-link-btn'
              data-cy='vdac-copy-accrual-link-btn'
              type='link'
              icon={
                <CopyOutlined
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                />
              }
              onClick={() => {
                copyToClipboard(
                  // TODO: update this to vdac_url (BE dependency)
                  transactionLine?.line_properties?.actions?.form_link
                    ?.url as string
                )
              }}
              style={{ color: '#725bb4' }}
            >
              Copy
            </Button>
          </div>
          <Card
            data-testid='vdac-form-link-card'
            data-cy='vdac-form-link-card'
            style={{ borderRadius: 12 }}
          >
            {/* TODO: update this to vdac_url */}
            {
              transactionLine?.line_properties?.actions?.form_link
                ?.url as string
            }
          </Card>
        </>
      )}
    </Modal>
  )
}

export default CopyAccrualFormLinkModal
