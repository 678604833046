import MENU_CONFIG from './MENU_CONFIG'

type SettingsConfig = {
  path: string
  label: string
  isHistoryEnabled?: boolean
}

const settingsConfig: SettingsConfig[] = [
  {
    path: '/settings/txn-enable-features',
    label: MENU_CONFIG.TXN_ENABLE_FEATURES.LABEL_NEW,
    isHistoryEnabled: true
  },
  {
    path: '/settings/user-management/users',
    label: MENU_CONFIG.USER_MANAGEMENT.USERS.LABEL
  },
  {
    path: '/settings/user-management/login-audit-trail',
    label: MENU_CONFIG.USER_MANAGEMENT.LOGIN_AUDIT_TRAIL.LABEL
  },
  {
    path: '/settings/schedule-manager',
    label: MENU_CONFIG.SCHEDULE_MANAGER.LABEL
  },
  {
    path: '/settings/period-management',
    label: MENU_CONFIG.PERIOD_MANAGEMENT.LABEL
  },
  {
    path: '/settings/review-center',
    label: MENU_CONFIG.REVIEW_CENTER.LABEL,
    isHistoryEnabled: true
  },
  {
    path: '/settings/transaction-column-manager',
    label: MENU_CONFIG.TXN_SETTINGS.TXN_COL_MANAGER.LABEL
  },
  {
    path: '/settings/accrual-rules',
    label: MENU_CONFIG.TASK_MANAGER.ACCRUAL_RULES.LABEL
  },
  {
    path: '/settings/accrual-analytic',
    label: MENU_CONFIG.ANALYTICS_SETTINGS.LABEL
  },
  {
    path: '/settings/integrations/api-keys',
    label: MENU_CONFIG.INTEGRATION.API_KEYS.LABEL
  },
  {
    path: '/settings/integrations/workday-setup',
    label: MENU_CONFIG.INTEGRATION.WORKDAY.LABEL
  },
  {
    path: '/settings/integrations/logs',
    label: MENU_CONFIG.INTEGRATION.LOGS.LABEL
  }
]

export default settingsConfig
