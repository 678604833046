/** antd imports */
import { ItemType } from 'antd/lib/menu/hooks/useItems'

/** zustand store imports */
import useFeatureFlagsStore from '../../../global/useFeatureFlagsStore'

/** constants imports */
import MENU_CONFIG from '../constants/MENU_CONFIG'

/** hooks imports */
import useRolesAndPermissions from '../../../hooks/useRolesAndPermissions'
import useGenerateMenuItem from './useGenerateMenuItem'
import useAnalyticsStore from '../../AccrualAnalytics/manager/analytics-store'
import useIsFlagEnabled from 'src/hooks/useIsFlagEnabled'

/** util imports */
import isGappifyAdministrator from '../utils/isGappifyAdministrator'
import isSystemAdministrator from '../utils/isSystemAdministrator'
import useCompanySettingsStore from '../../../global/useCompanySettingsStore'
import { FEATURE_FLAGS } from 'src/features/Flag/constants'

/**
 * Get settings sidebar menu items
 * @param pathName string
 */
export default function useGetMenuItems(pathName: string) {
  /** zustand state */
  const { gateway, workday } = useFeatureFlagsStore()
  const { isAnalytics } = useAnalyticsStore()
  const { api_enabled, workday_enabled } = useCompanySettingsStore()

  /** hooks */
  const { generateMenuItem, generateMenuLabel } = useGenerateMenuItem()
  const RP = useRolesAndPermissions()
  const isFlagEnabled = useIsFlagEnabled()

  const menuItems: ItemType[] = []

  const userHasSelfServiceUserManagementPermission: boolean =
    RP.USER_HAS_SELF_SERVICE_USER_MANAGEMENT_PERMISSION

  const userHasLoginAuditTrailPermission: boolean =
    RP.USER_HAS_LOGIN_AUDIT_TRAIL_PERMISSION

  /** integrations pages permissions */
  const userHasApiKeyPermission: boolean =
    RP.USER_HAS_API_MANAGER_GET_PERMISSION
  const userHasWorkdayPermission: boolean = userHasApiKeyPermission
  const userHasIntegrationsPermissions: boolean =
    userHasApiKeyPermission || userHasWorkdayPermission
  const isIntegrationsEnabled: boolean =
    api_enabled || workday_enabled || isFlagEnabled(FEATURE_FLAGS.integration)
  const localStorageItem = localStorage.getItem('rolesAndPermissions')
  const userRoles = localStorageItem ? JSON.parse(localStorageItem).roles : []

  const checkCloseManagerMenu = () => {
    const rolesHasSettings = [
      'Close-Manager',
      'Gappify-Administrator',
      'System-Administrator',
      'Full-User'
    ]
    if (userRoles.length === 0) {
      return false
    }

    return rolesHasSettings.some((str) => userRoles.includes(str))
  }

  const showReviewCenterMenu = () => {
    const rolesHasReviewCenterMenu = [
      'Close-Manager',
      'Gappify-Administrator',
      'System-Administrator',
      'Full-User'
    ]
    if (userRoles.length === 0) {
      return false
    }

    return rolesHasReviewCenterMenu.some((str) => userRoles.includes(str))
  }

  /**
   * Conditions to add Period Management in the collapsible menu:
   * - User has Period Manager Settings permission
   * - edinburgh_period_management feature flag is enabled
   */
  if (
    RP.USER_HAS_PERIOD_MANAGER_SETTINGS_PERMISSION &&
    isFlagEnabled(FEATURE_FLAGS.edinburgh_period_management)
  ) {
    const rcSettingsLink = generateMenuItem(pathName, '/settings', {
      LABEL: MENU_CONFIG.PERIOD_MANAGEMENT.LABEL,
      KEY: MENU_CONFIG.PERIOD_MANAGEMENT.KEY,
      WEIGHT: MENU_CONFIG.PERIOD_MANAGEMENT.WEIGHT
    })
    menuItems.push(rcSettingsLink)
  }

  // Check if user has users page permission
  // or roles page permission
  // or login audit trail page permission
  if (userHasSelfServiceUserManagementPermission && checkCloseManagerMenu()) {
    const userManagementPages: any = {
      label: MENU_CONFIG.USER_MANAGEMENT.LABEL,
      key: MENU_CONFIG.USER_MANAGEMENT.KEY,
      weight: MENU_CONFIG.USER_MANAGEMENT.WEIGHT,
      children: []
    }

    // Check if user has users page permission
    if (userHasSelfServiceUserManagementPermission) {
      const usersLink = generateMenuItem(
        pathName,
        '/settings/user-management',
        {
          LABEL: MENU_CONFIG.USER_MANAGEMENT.USERS.LABEL,
          KEY: MENU_CONFIG.USER_MANAGEMENT.USERS.KEY
        }
      )

      // add users page to userManagementPages
      userManagementPages.children.push(usersLink)
    }

    // Check if user has login audit trail page permission
    if (userHasLoginAuditTrailPermission) {
      const loginAuditTrailLink = generateMenuItem(
        pathName,
        '/settings/user-management',
        {
          LABEL: MENU_CONFIG.USER_MANAGEMENT.LOGIN_AUDIT_TRAIL.LABEL,
          KEY: MENU_CONFIG.USER_MANAGEMENT.LOGIN_AUDIT_TRAIL.KEY
        }
      )

      // add login audit trail page page to userManagementPages
      userManagementPages.children.push(loginAuditTrailLink)
    }

    // add userManagementPages to menuItems
    menuItems.push(userManagementPages)
  }

  // Check if user has shedule manager page permission
  // Add schedule manager menu item to sidebar if
  if (RP.USER_HAS_SCHEDULE_MANAGER_GET_PERMISSION) {
    const scheduleManagerLink = generateMenuItem(pathName, '/settings', {
      LABEL: MENU_CONFIG.SCHEDULE_MANAGER.LABEL,
      KEY: MENU_CONFIG.SCHEDULE_MANAGER.KEY,
      WEIGHT: MENU_CONFIG.SCHEDULE_MANAGER.WEIGHT
    })

    // add scheduleManager to menuItems
    menuItems.push(scheduleManagerLink)
  }

  if (
    (RP.USER_HAS_REVIEW_CENTER_SETTINGS_PERMISSION || showReviewCenterMenu()) &&
    isFlagEnabled(FEATURE_FLAGS.edmonton) // Added a separate inline feature flag check for Dynamic Review Center
  ) {
    const rcSettingsLink = generateMenuItem(pathName, '/settings', {
      LABEL: MENU_CONFIG.REVIEW_CENTER.LABEL,
      KEY: MENU_CONFIG.REVIEW_CENTER.KEY
    })
    menuItems.push(rcSettingsLink)
  }

  // Check if user has accrual manager page permission
  if (RP.USER_HAS_ACCRUAL_RULES_MANAGER_SHOW) {
    const tsmPages: any = {
      label: MENU_CONFIG.TASK_MANAGER.LABEL,
      key: MENU_CONFIG.TASK_MANAGER.KEY,
      weight: MENU_CONFIG.TASK_MANAGER.WEIGHT,
      children: []
    }

    // Check is user has accrual rules page permission
    if (RP.USER_HAS_ACCRUAL_RULES_MANAGER_SHOW) {
      const accrualRulePageLink = generateMenuItem(pathName, '/settings', {
        LABEL: MENU_CONFIG.TASK_MANAGER.ACCRUAL_RULES.LABEL,
        KEY: MENU_CONFIG.TASK_MANAGER.ACCRUAL_RULES.KEY
      })

      // add accrualRulePageLink to tsmPages
      tsmPages.children.push(accrualRulePageLink)
    }

    // add tsmPages to menuItems
    menuItems.push(tsmPages)

    if (isGappifyAdministrator() && checkCloseManagerMenu()) {
      menuItems.push(
        generateMenuItem(pathName, '/settings', {
          LABEL: MENU_CONFIG.TXN_ENABLE_FEATURES.LABEL_NEW,
          KEY: MENU_CONFIG.TXN_ENABLE_FEATURES.KEY,
          WEIGHT: MENU_CONFIG.TXN_ENABLE_FEATURES.WEIGHT
        })
      )
    }
  }

  // Check if user has integrations page permission
  // gateway is API Integrations feature flag
  if (
    gateway &&
    userHasIntegrationsPermissions &&
    isIntegrationsEnabled &&
    checkCloseManagerMenu()
  ) {
    const integrationsPages: any = {
      label: generateMenuLabel(MENU_CONFIG.INTEGRATION.LABEL, { beta: true }),
      key: MENU_CONFIG.INTEGRATION.KEY,
      weight: MENU_CONFIG.INTEGRATION.WEIGHT,
      children: []
    }

    // Check if user has api key page permission
    if (gateway && userHasApiKeyPermission && api_enabled) {
      const apiKeyLink = generateMenuItem(pathName, '/settings/integrations', {
        LABEL: MENU_CONFIG.INTEGRATION.API_KEYS.LABEL,
        KEY: MENU_CONFIG.INTEGRATION.API_KEYS.KEY
      })

      // add login audit trail page page to integrationsPages
      integrationsPages.children.push(apiKeyLink)
    }

    // Check if user has workday settings permission
    if (workday && userHasWorkdayPermission && workday_enabled) {
      const apiKeyLink = generateMenuItem(pathName, '/settings/integrations', {
        LABEL: MENU_CONFIG.INTEGRATION.WORKDAY.LABEL,
        KEY: MENU_CONFIG.INTEGRATION.WORKDAY.KEY
      })

      // add login audit trail page page to integrationsPages
      integrationsPages.children.push(apiKeyLink)
    }

    // Check if user has logs permission
    if (
      isFlagEnabled(FEATURE_FLAGS.integration) &&
      RP.USER_HAS_INTEGRATION_LOGS_GET_PERMISSION && api_enabled
    ) {
      const apiKeyLink = generateMenuItem(pathName, '/settings/integrations', {
        LABEL: MENU_CONFIG.INTEGRATION.LOGS.LABEL,
        KEY: MENU_CONFIG.INTEGRATION.LOGS.KEY
      })

      // add login audit trail page page to integrationsPages
      integrationsPages.children.push(apiKeyLink)
    }

    // add userManagementPages to menuItems
    menuItems.push(integrationsPages)
  }

  if (isAnalytics) {
    const addOnModulePages: any = {
      label: MENU_CONFIG.ADD_ON_MODULE.LABEL,
      key: MENU_CONFIG.ADD_ON_MODULE.KEY,
      weight: MENU_CONFIG.ADD_ON_MODULE.WEIGHT,
      children: []
    }

    if (isAnalytics) {
      const analyticsSettingsLink = generateMenuItem(pathName, '/settings', {
        LABEL: MENU_CONFIG.ADD_ON_MODULE.ANALYTICS_SETTINGS.LABEL,
        KEY: MENU_CONFIG.ADD_ON_MODULE.ANALYTICS_SETTINGS.KEY
      })
      addOnModulePages.children.push(analyticsSettingsLink)
    }

    menuItems.push(addOnModulePages)
  }

  if (
    (isGappifyAdministrator() || isSystemAdministrator()) &&
    checkCloseManagerMenu()
  ) {
    const transactionsPages: any = {
      label: MENU_CONFIG.TXN_SETTINGS.LABEL,
      key: MENU_CONFIG.TXN_SETTINGS.KEY,
      weight: MENU_CONFIG.TXN_SETTINGS.WEIGHT,
      children: []
    }

    if (RP.USER_HAS_CONSOLIDATED_TRANSACTION_PERMISSION) {
      const columnManagerLink = generateMenuItem(pathName, '/settings', {
        LABEL: MENU_CONFIG.TXN_SETTINGS.TXN_COL_MANAGER.LABEL,
        KEY: MENU_CONFIG.TXN_SETTINGS.TXN_COL_MANAGER.KEY
      })

      // add users page to userManagementPages
      transactionsPages.children.push(columnManagerLink)
    }

    menuItems.push(transactionsPages)
  }

  const getSortedItems = (items: any[]) => {
    return items.sort((a, b) => {
      return a.weight - b.weight
    })
  }

  return { menuItems: getSortedItems(menuItems) }
}
