/** zustand imports */
import { create } from 'zustand'

interface StoreProps {
  /** state */
  loginErrorMessage: string
  oktaEmail: string
  azureEmail: string

  /** actions */
  setLoginErrorMessage: (loginErrorMessage: string) => void
  setOktaEmail: (oktaEmail: string) => void
  setAzureEmail: (azureEmail: string) => void
}

// =================================================================
const useLoginStore = create<StoreProps>((set) => ({
  /** state */
  loginErrorMessage: '',
  oktaEmail: '',
  azureEmail: '',

  /** actions */
  setLoginErrorMessage: (loginErrorMessage) => set({ loginErrorMessage }),
  setOktaEmail: (oktaEmail) => set({ oktaEmail }),
  setAzureEmail: (azureEmail) => set({ azureEmail })
}))

export default useLoginStore
