import { JSXElementConstructor, ReactElement } from 'react'
import { useRoutes } from 'react-router-dom'
import publicRoutes from './publicRoutes'

const Routes = (): ReactElement<
  any,
  string | JSXElementConstructor<any>
> | null => {
  return useRoutes(publicRoutes)
}

export default Routes
