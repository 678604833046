import React, { useState, useEffect } from 'react'
import GDropdownBtn from '../gappify/GDropdownBtn'
import { ExportOutlined, DownOutlined } from '@ant-design/icons'
import { ReviewCenterViews } from '../../utils/enums/ReviewCenterViewsEnum'
import GButton from '../gappify/GButton'
import GIcon from '../gappify/GIcon'
import { faEye, faPencil } from '@fortawesome/free-solid-svg-icons'
import type { ItemType as MenuItemType } from 'antd/lib/menu/hooks/useItems'
import { Col, DatePicker, Row, Space } from 'antd'
import moment from 'moment'

type MenuItemTypeWithTestAttrs = MenuItemType & {
  'data-testid'?: string
  'data-cy'?: string
}

const FilterBar: React.FC<any> = ({
  config,
  onViewChange,
  onPeriodChange,
  activeFilter,
  setActiveFilter,
  customFilters,
  setCustomFilters,
  handleFilterMenuOnClick,
  allFilters,
  showEditButton,
  onExportClick,
  previewMode,
  previewTriggeredFrom,
  setIsShowCreateNewFilterModal,
  setIsShowUpdateFilterModal
}) => {
  const [showDatePicker, setShowDatePicker] = useState(false)

  const getPrevMonths = () => {
    const menu: MenuItemType[] = []

    if (config.periods) {
      Object.entries(config.periods).forEach(([key, value]) => {
        menu.push({
          label: value as string,
          key: key
        })
      })
    }
    menu.push({
      label: (
        <DatePicker
          className='period-picker'
          picker='month'
          placeholder='Select Period'
          format='MMMM YYYY'
          onChange={(date) => {
            if (date) {
              setShowDatePicker(false)
              setPeriodLabel(moment(date).format('MMMM YYYY'))
              onPeriodChange(moment(date).startOf('month').format('YYYY-MM-DD'))
            }
          }}
        />
      ),
      key: 'date-picker'
    })

    return menu
  }

  const getViewOptions = () => {
    return Object.entries(ReviewCenterViews).map((viewOption) => {
      return {
        key: viewOption[0],
        label: viewOption[1],
        'data-cy': `view-option-${viewOption[0]}`,
        'data-testid': `view-option-${viewOption[0]}`
      }
    })
  }

  const [periodLabel, setPeriodLabel] = useState('')
  const [viewLabel, setViewLabel] = useState('')

  const handleOnClickPeriod = (item: any) => {
    if (item?.key !== 'date-picker') {
      setPeriodLabel(moment(item?.key).format('MMMM YYYY'))
      onPeriodChange(item?.key)
      setShowDatePicker(false)
    }
  }

  const handleOnClickView = (item: any) => {
    if (viewLabel !== ReviewCenterViews[item.key]) {
      setViewLabel(ReviewCenterViews[item.key])
      onViewChange(item.key)
    }
  }

  const getFilterMenu = () => {
    return [
      ...customFilters.map((filter: any) => {
        return {
          key: filter.id,
          label: filter.name,
          'data-cy': `filter-option-${filter.id}`,
          'data-testid': `filter-option-${filter.id}`
        }
      }),
      {
        type: 'divider'
      },
      {
        key: 'all',
        label: 'All',
        'data-cy': `filter-option-all`,
        'data-testid': `filter-option-all`
      },
      {
        type: 'divider'
      },
      {
        key: 'new',
        label: 'Create New Filter',
        'data-cy': `filter-option-new`,
        'data-testid': `filter-option-new`
      }
    ]
  }

  const getExportMenu = (): MenuItemTypeWithTestAttrs[] => {
    const menu: MenuItemTypeWithTestAttrs[] = [
      {
        label: 'Excel',
        key: 'excel',
        'data-cy': `export-option-excel`,
        'data-testid': `export-option-excel`
      }
    ]

    return menu
  }

  const getFilterLabel = (key: string) =>
    getFilterMenu().find((filter: any) => `${filter.key}` === `${key}`)?.label

  const truncateFilterLabel = (label: string) => {
    if (label.length > 15) return `${label.slice(0, 15).trim()}...`
    else return label
  }

  useEffect(() => {
    setPeriodLabel(config.periods[config.default_period])
    setViewLabel(ReviewCenterViews[config.default_view_name])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ marginBottom: '15px' }}>
      <Row justify='space-between'>
        <Col span={4}>
          <Space>
            <GDropdownBtn
              dataCy='rc-period-dropdown'
              dataTestId='rc-period-dropdown'
              menuItems={getPrevMonths()}
              btnText={periodLabel}
              children={<DownOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} /> }
              onClick={handleOnClickPeriod}
              visible={showDatePicker}
              onVisibleChange={setShowDatePicker}
            />

            <GDropdownBtn
              className='g-btn-default'
              dataCy='rc-filter-dropdown'
              dataTestId='rc-filter-dropdown'
              onClick={handleFilterMenuOnClick}
              menuItems={getFilterMenu()}
              btnText={truncateFilterLabel(`${getFilterLabel(activeFilter)}`)}
              children={<DownOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} /> }
              overlayStyle={{
                maxHeight: '300px',
                overflowY: 'scroll',
                boxShadow: '5px 8px 24px 5px rgba(0, 0, 0, 0.1)'
              }}
            />
            {previewMode ? (
              <GButton
                dataCy='rc-eye-btn'
                dataTestId='rc-eye-btn'
                type='text'
                className='g-btn-icon'
                onClick={() => {
                  if (previewTriggeredFrom === 'new')
                    setIsShowCreateNewFilterModal(true)
                  if (previewTriggeredFrom === 'update')
                    setIsShowUpdateFilterModal(true)
                }}
                icon={<GIcon icon={faEye} />}
              />
            ) : showEditButton ? (
              <GButton
                dataCy='rc-update-btn'
                dataTestId='rc-update-btn'
                type='text'
                className='g-btn-icon'
                onClick={() => setIsShowUpdateFilterModal(true)}
                icon={<GIcon icon={faPencil} />}
              />
            ) : null}
          </Space>
        </Col>
        <Col span={4} style={{ textAlign: 'center' }}>
          <GDropdownBtn
            dataCy='rc-view-dropdown'
            dataTestId='rc-view-dropdown'
            menuItems={getViewOptions()}
            btnText={viewLabel}
            children={<DownOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} /> }
            onClick={handleOnClickView}
          />
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <GDropdownBtn
            dataCy='rc-export-dropdown'
            dataTestId='rc-export-dropdown'
            className='g-btn-default'
            menuItems={getExportMenu()}
            btnText='Export to'
            btnIcon={<ExportOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} /> }
            children={<DownOutlined onPointerEnterCapture={() => {}}
  onPointerLeaveCapture={() => {}} /> }
            onClick={onExportClick}
          />
        </Col>
      </Row>
    </div>
  )
}

export default FilterBar
