import { FC, useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './context/store'
import Routes from './routes'
import { PENDO_METADATA } from './helpers/constants/Auth'
import { pendoInitialize } from './utils/pendo-utils'
import QueryProvider from './providers/QueryProvider'

const txtlink: string = '/version.txt'

const App: FC = (): JSX.Element => {
  useEffect(() => {
    const main = () => {
      fetch(txtlink)
        .then((res) => res.text())
        .then((text) => {
          if (!text.includes('<!DOCTYPE html>')) {
            localStorage.setItem('version', text)
          } else {
            localStorage.removeItem('version')
          }
        })
    }
    main()
  }, [])

  useEffect(() => {
    if (localStorage.getItem(PENDO_METADATA)) {
      pendoInitialize(JSON.parse(localStorage.getItem(PENDO_METADATA)!))
    }
  }, [])

  return (
    <QueryProvider>
      <Provider store={store}>
        <Router>
          <Routes />
        </Router>
      </Provider>
    </QueryProvider>
  )
}

export default App
