/** antd imports */
import { ItemType } from 'antd/lib/menu/hooks/useItems'

/** zustand store imports */
import useFeatureFlagsStore from '../../../global/useFeatureFlagsStore'

/** constants imports */
import MENU_CONFIG from '../constants/MENU_CONFIG'

/** hooks imports */
import useRolesAndPermissions from '../../../hooks/useRolesAndPermissions'
import useGenerateMenuItem from './useGenerateMenuItem'
import useAnalyticsStore from '../../AccrualAnalytics/manager/analytics-store'
import useIsFlagEnabled from 'src/hooks/useIsFlagEnabled'

/** util imports */
import isGappifyAdministrator from '../utils/isGappifyAdministrator'
import isSystemAdministrator from '../utils/isSystemAdministrator'
import useCompanySettingsStore from '../../../global/useCompanySettingsStore'
import { FEATURE_FLAGS } from 'src/features/Flag/constants'

/**
 * Get settings sidebar menu items
 * @param pathName string
 */
export default function useGetMenuItems(pathName: string) {
  /** zustand state */
  const { gateway, workday } = useFeatureFlagsStore()
  const { isAnalytics } = useAnalyticsStore()
  const { api_enabled, workday_enabled } = useCompanySettingsStore()

  /** hooks */
  const { generateMenuItem, generateMenuLabel } = useGenerateMenuItem()
  const RP = useRolesAndPermissions()
  const isFlagEnabled = useIsFlagEnabled()

  const menuItems: ItemType[] = []

  const userHasSelfServiceUserManagementPermission: boolean =
    RP.USER_HAS_SELF_SERVICE_USER_MANAGEMENT_PERMISSION

  const userHasLoginAuditTrailPermission: boolean =
    RP.USER_HAS_LOGIN_AUDIT_TRAIL_PERMISSION

  /** integrations pages permissions */
  const userHasApiKeyPermission: boolean =
    RP.USER_HAS_API_MANAGER_GET_PERMISSION
  const userHasWorkdayPermission: boolean = userHasApiKeyPermission
  const userHasIntegrationsPermissions: boolean =
    userHasApiKeyPermission || userHasWorkdayPermission
  const isIntegrationsEnabled: boolean =
    api_enabled || workday_enabled || isFlagEnabled(FEATURE_FLAGS.integration)

  // Check if user has users page permission
  // or roles page permission
  // or login audit trail page permission
  if (userHasSelfServiceUserManagementPermission) {
    const userManagementPages: ItemType = {
      label: MENU_CONFIG.USER_MANAGEMENT.LABEL,
      key: MENU_CONFIG.USER_MANAGEMENT.KEY,
      children: []
    }

    // Check if user has users page permission
    if (userHasSelfServiceUserManagementPermission) {
      const usersLink = generateMenuItem(
        pathName,
        '/settings/user-management',
        {
          LABEL: MENU_CONFIG.USER_MANAGEMENT.USERS.LABEL,
          KEY: MENU_CONFIG.USER_MANAGEMENT.USERS.KEY
        }
      )

      // add users page to userManagementPages
      userManagementPages.children.push(usersLink)
    }

    // Check if user has login audit trail page permission
    if (userHasLoginAuditTrailPermission) {
      const loginAuditTrailLink = generateMenuItem(
        pathName,
        '/settings/user-management',
        {
          LABEL: MENU_CONFIG.USER_MANAGEMENT.LOGIN_AUDIT_TRAIL.LABEL,
          KEY: MENU_CONFIG.USER_MANAGEMENT.LOGIN_AUDIT_TRAIL.KEY
        }
      )

      // add login audit trail page page to userManagementPages
      userManagementPages.children.push(loginAuditTrailLink)
    }

    // add userManagementPages to menuItems
    menuItems.push(userManagementPages)
  }

  // Check if user has shedule manager page permission
  // Add schedule manager menu item to sidebar if
  if (RP.USER_HAS_SCHEDULE_MANAGER_GET_PERMISSION) {
    const scheduleManagerLink = generateMenuItem(pathName, '/settings', {
      LABEL: MENU_CONFIG.SCHEDULE_MANAGER.LABEL,
      KEY: MENU_CONFIG.SCHEDULE_MANAGER.KEY
    })

    // add scheduleManager to menuItems
    menuItems.push(scheduleManagerLink)
  }

  // Check if user has accrual manager page permission
  if (RP.USER_HAS_ACCRUAL_RULES_MANAGER_SHOW) {
    const tsmPages: ItemType = {
      label: MENU_CONFIG.TASK_MANAGER.LABEL,
      key: MENU_CONFIG.TASK_MANAGER.KEY,
      children: []
    }

    // Check is user has accrual rules page permission
    if (RP.USER_HAS_ACCRUAL_RULES_MANAGER_SHOW) {
      const accrualRulePageLink = generateMenuItem(pathName, '/settings', {
        LABEL: MENU_CONFIG.TASK_MANAGER.ACCRUAL_RULES.LABEL,
        KEY: MENU_CONFIG.TASK_MANAGER.ACCRUAL_RULES.KEY
      })

      // add accrualRulePageLink to tsmPages
      tsmPages.children.push(accrualRulePageLink)
    }

    // add tsmPages to menuItems
    menuItems.push(tsmPages)

    if (isGappifyAdministrator()) {
      menuItems.push(
        generateMenuItem(pathName, '/settings', {
          LABEL: MENU_CONFIG.TXN_ENABLE_FEATURES.LABEL,
          KEY: MENU_CONFIG.TXN_ENABLE_FEATURES.KEY
        })
      )
    }
  }

  // Check if user has integrations page permission
  // gateway is API Integrations feature flag
  if (gateway && userHasIntegrationsPermissions && isIntegrationsEnabled) {
    const integrationsPages: ItemType = {
      label: generateMenuLabel(MENU_CONFIG.INTEGRATION.LABEL, { beta: true }),
      key: MENU_CONFIG.INTEGRATION.KEY,
      children: []
    }

    // Check if user has api key page permission
    if (gateway && userHasApiKeyPermission && api_enabled) {
      const apiKeyLink = generateMenuItem(pathName, '/settings/integrations', {
        LABEL: MENU_CONFIG.INTEGRATION.API_KEYS.LABEL,
        KEY: MENU_CONFIG.INTEGRATION.API_KEYS.KEY
      })

      // add login audit trail page page to integrationsPages
      integrationsPages.children.push(apiKeyLink)
    }

    // Check if user has workday settings permission
    if (workday && userHasWorkdayPermission && workday_enabled) {
      const apiKeyLink = generateMenuItem(pathName, '/settings/integrations', {
        LABEL: MENU_CONFIG.INTEGRATION.WORKDAY.LABEL,
        KEY: MENU_CONFIG.INTEGRATION.WORKDAY.KEY
      })

      // add login audit trail page page to integrationsPages
      integrationsPages.children.push(apiKeyLink)
    }

    // Check if user has logs permission
    if (
      isFlagEnabled(FEATURE_FLAGS.integration) &&
      RP.USER_HAS_INTEGRATION_LOGS_GET_PERMISSION && api_enabled
    ) {
      const apiKeyLink = generateMenuItem(pathName, '/settings/integrations', {
        LABEL: MENU_CONFIG.INTEGRATION.LOGS.LABEL,
        KEY: MENU_CONFIG.INTEGRATION.LOGS.KEY
      })

      // add login audit trail page page to integrationsPages
      integrationsPages.children.push(apiKeyLink)
    }

    // add userManagementPages to menuItems
    menuItems.push(integrationsPages)
  }

  if (isAnalytics) {
    const analyticsSettingsLink = generateMenuItem(pathName, '/settings', {
      LABEL: MENU_CONFIG.ANALYTICS_SETTINGS.LABEL,
      KEY: MENU_CONFIG.ANALYTICS_SETTINGS.KEY
    })
    // add Analytics Settings to menuItems
    menuItems.push(analyticsSettingsLink)
  }

  if (isGappifyAdministrator() || isSystemAdministrator()) {
    const transactionsPages: ItemType = {
      label: MENU_CONFIG.TXN_SETTINGS.LABEL,
      key: MENU_CONFIG.TXN_SETTINGS.KEY,
      children: []
    }

    if (RP.USER_HAS_CONSOLIDATED_TRANSACTION_PERMISSION) {
      const columnManagerLink = generateMenuItem(pathName, '/settings', {
        LABEL: MENU_CONFIG.TXN_SETTINGS.TXN_COL_MANAGER.LABEL,
        KEY: MENU_CONFIG.TXN_SETTINGS.TXN_COL_MANAGER.KEY
      })

      // add users page to userManagementPages
      transactionsPages.children.push(columnManagerLink)
    }

    menuItems.push(transactionsPages)
  }

  return { menuItems }
}
