/** react imports */
import { useEffect } from 'react'

/** react router imports */
import { useLocation, useNavigate } from 'react-router-dom'

/** custom component imports */
import AppVersion from '../../components/AppVersion'
import ResetPasswordModal from '../../components/ResetPasswordModal'
import LoginForm from './components/LoginForm'
import LoginHeader from './components/LoginHeader'

/** zustand imports */
import { useGlobalStore } from '../../global/global-store'

/** helpers imports */
import { ROLES_AND_PERMISSIONS } from '../../helpers/constants/Auth'

/** hooks imports */
import useHandleLoginSSO from './hooks/useHandleLoginSSO'
import useLogin from './hooks/useLogin'
import useLoginSSO from './hooks/useLoginSSO'

// =================================================================
export default function Login() {
  /** react router */
  const navigate = useNavigate()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const uid = searchParams.get('uid')

  /** hooks */
  const { redirectPage, isAuthTokenValid } = useLogin()
  const {
    data: loginSsoData,
    refetch: checkAuth,
    isSuccess,
    isError,
    error
  } = useLoginSSO(uid)
  useHandleLoginSSO(isSuccess, isError, error, loginSsoData)

  /** zustand store */
  const { isPasswordExpired, passwordResetToken } = useGlobalStore()

  const isTokenValid = isAuthTokenValid()

  useEffect(() => {
    if (uid) {
      checkAuth()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid])

  useEffect(() => {
    if (isTokenValid) {
      redirectPage(
        JSON.parse(localStorage.getItem(ROLES_AND_PERMISSIONS) || '{}')
      )
    }
  }, [isTokenValid, redirectPage])

  isPasswordExpired && navigate(`/password/${passwordResetToken}`)

  return (
    <div className='login-page'>
      <LoginHeader />
      <LoginForm />
      <ResetPasswordModal />
      <AppVersion />
    </div>
  )
}
