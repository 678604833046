/** antd imports */
import { Button } from 'antd'

/** react imports */
import { useEffect } from 'react'

/** custom component imports */
import AzureModal from './AzureModal'
import LoginGoogleIcon from './LoginGoogleIcon'
import OktaModal from './OktaModal'

/** hooks imports */
import useGoogleLogin from '../hooks/useGoogleLogin'

// =================================================================
export default function LoginSSO() {
  /** hooks */
  const {
    data: googleLoginData,
    refetch: onClickGoogleSignin,
    isSuccess: isGoogleLoginSuccess,
    isFetching
  } = useGoogleLogin()

  useEffect(() => {
    if (isGoogleLoginSuccess) {
      window.location.replace(googleLoginData)
    }
  }, [googleLoginData, isGoogleLoginSuccess])

  return (
    <div
      style={{
        marginTop: '50px'
      }}
    >
      <Button
        data-test-id='login-google-btn'
        data-cy='login-google-btn'
        className='google-btn'
        type='primary'
        icon={<LoginGoogleIcon />}
        onClick={() => onClickGoogleSignin()}
        loading={isFetching}
      >
        SIGN IN WITH GOOGLE
      </Button>
      <OktaModal />
      <AzureModal />
    </div>
  )
}
