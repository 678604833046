// ** Zustand Imports
import { create } from 'zustand'

// ** Type Imports
import SettingsProps from '../types/SettingsStore'
import TableRecord from '../../AccrualAnalytics/types/TableRecord'

// =================================================================
const useSettingsStore = create<SettingsProps>((set) => ({
  // Define initial state
  banner: [],
  collapsed: false,
  selectedItem: null,
  internalPage: 1,
  vendorPage: 1,
  timingPage: 1,
  volumePage: 1,
  accuracyPage: 1,
  completenessPage: 1,
  title: '',
  internalSort: 'desc',
  vendorSort: 'desc',
  timingSort: 'desc',
  volumeSort: 'desc',
  accuracySort: 'desc',
  completenessSort: 'desc',
  hovered: false,
  selectedRowKeys: {},
  sort: '0:asc',
  sortState: [],
  buttonClicked: '',
  isAnalytics: undefined,
  openModal: false,
  modalType: '',
  responseType: undefined,
  period: undefined,
  permNotesVendorName: undefined,
  selectedSettings: '',
  isHistoryEnabled: false,

  // Define actions
  setBanner: (banner) => set({ banner }),
  setCollapsed: (collapsed) => set({ collapsed }),
  setSelectedItem: (selectedItem) => set({ selectedItem }),
  setInternalPage: (internalPage) => set({ internalPage }),
  setVolumePage: (volumePage) => set({ volumePage }),
  setVendorPage: (vendorPage) => set({ vendorPage }),
  setTimingPage: (timingPage) => set({ timingPage }),
  setAccuracyPage: (accuracyPage) => set({ accuracyPage }),
  setCompletenessPage: (completenessPage) => set({ completenessPage }),
  setTitle: (title) => set({ title }),
  setInternalSort: (internalSort) => set({ internalSort }),
  setVendorSort: (vendorSort) => set({ vendorSort }),
  setTimingSort: (timingSort) => set({ timingSort }),
  setVolumeSort: (volumeSort) => set({ volumeSort }),
  setAccuracySort: (accuracySort) => set({ accuracySort }),
  setCompletnessSort: (completenessSort) => set({ completenessSort }),
  setHovered: (hovered) => set({ hovered }),
  setSelectedRowKeys: (selectedRowKeys) => set({ selectedRowKeys }),
  setSort: (sort) => set({ sort }),
  setSortState: (sortState) => set({ sortState }),
  setButtonClicked: (buttonClicked) => set({ buttonClicked }),
  setIsAnalytics: (isAnalytics) => set({ isAnalytics }),
  setOpenModal: (value) => set({ openModal: value }),
  setModalType: (value) => set({ modalType: value }),
  setResponseType: (responseType) => set({ responseType }),
  setPeriod: (period) => set({ period }),
  setPermNotesVendorName: (value) => set({ permNotesVendorName: value }),
  setSelectedSettings: (value) => set({ selectedSettings: value }),
  setIsHistoryEnabled: (value) => set({ isHistoryEnabled: value }),

  // Functions
  handleMenuClick: (key: string, record: TableRecord, button: string) => {
    set({ buttonClicked: button })
    set({ permNotesVendorName: record[0] })
    console.log('Menu Click on row: ', record[0])
    console.log('Clicked on Menu Item: ', key)
    console.log('buttonclicked', button)
    switch (key) {
      case '1':
        console.log(`Update Accrual Manager Clicked`)
        set({ openModal: true, modalType: 'update' })
        break
      case '2':
        console.log(`Perm Notes Clicked`)
        set({ openModal: true, modalType: 'perm' })
        break
      case '3':
        console.log(`History Log Clicked`)
        set({ openModal: true, modalType: 'history' })
        break
      default:
        break
    }
  }
}))

export default useSettingsStore
