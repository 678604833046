/** React imports */
import { FC, useEffect } from 'react'
import { Spin } from 'antd'

/** Redux imports */
import { useDispatch, useSelector } from 'react-redux'
import setError from '../context/actions/errorAction'
import setForbiddenError from '../context/actions/forbiddenErrorAction'

/** React Router imports */
import { Outlet, useLocation } from 'react-router-dom'

/** Custom components imports */
import AppVersion from '../components/AppVersion'
import ErrorModal from '../components/ErrorModal'
import ForbiddenErrorModal from '../components/ForbiddenErrorModal'
// import UploadFileModal from '../components/review-center/UploadFileModal'
import Header from './Header'
import UploadFileModal from './UploadFileModal'

/** Hooks imports */
import useCompanySettings from '../hooks/useCompanySettings'
import useFeatureFlags from '../hooks/useFeatureFlags'

/** Types imports */
import type ErrorPayload from '../types/ErrorPayload'

/** Zustand imports */
import useFeatureFlagsStore from '../global/useFeatureFlagsStore'
import useTxnGlobalSettingsStore from '../global/useTxnGlobalSettingsStore'
import useTxnGlobalSettings from '../hooks/useTxnGlobalSettings'
import useAnalyticsStore from '../pages/AccrualAnalytics/manager/analytics-store'
import useUploadFileModalStore from './UploadFileModal/manager/useUploadFileModalStore'
import useCompanySettingsStore from '../global/useCompanySettingsStore'
import useFlagStore, { TFlag } from '../features/Flag/stores/flagStore'

// =================================================================
const Base: FC = (): JSX.Element => {
  /** react router */
  const location = useLocation()
  const isAnalytics = location.pathname.includes('analytics')

  /** redux store */
  const dispatch = useDispatch()
  const error = useSelector(
    (state: { allErrors: ErrorPayload }) => state.allErrors
  )
  const forbiddenError = useSelector((state: any) => state.allForbiddenErrors)

  /** react state */
  // const [isUploadFileModalVisible, setIsUploadFileModalVisible] =
  //   useState<boolean>(false)

  /** zustand store */
  const { setIsAnalytics } = useAnalyticsStore()
  const { setGateway, setDublin, setWorkday } = useFeatureFlagsStore()
  const { setIsImportAccrualEnabled, setIsSourceDataEnabled } =
    useUploadFileModalStore()
  const { setTxnGlobalSettings, setIsConsolidatedTxnLines } =
    useTxnGlobalSettingsStore()
  const { setApiEnabled, setWorkdayEnabled } = useCompanySettingsStore()
  const setFlags = useFlagStore((state) => state.setFlags)
  const setIsFlagsLoading = useFlagStore((state) => state.setIsFlagsLoading)
  const isFlagsLoading = useFlagStore((state) => state.isFlagsLoading)

  /** hooks */
  const { data: companySettings, isSuccess: isFetchCompanySettingsSuccess } =
    useCompanySettings()
  const { data: featureFlags, isSuccess: isFetchFeatureFlagsSuccess } =
    useFeatureFlags()
  const {
    data: txnGlobalSettings,
    isSuccess: isFetchTxnGlobalSettingsSuccess
  } = useTxnGlobalSettings()

  useEffect(() => {
    if (isFetchCompanySettingsSuccess) {
      setIsAnalytics(companySettings.display_advanced_analytics)
      setIsSourceDataEnabled(companySettings.enable_upload_files)
      setIsImportAccrualEnabled(companySettings.enable_import_accruals)
      setIsConsolidatedTxnLines(companySettings.consolidated_transaction_lines)
      setApiEnabled(companySettings.api_enabled)
      setWorkdayEnabled(companySettings.workday_enabled)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchCompanySettingsSuccess])

  useEffect(() => {
    if (isFetchFeatureFlagsSuccess) {
      setDublin(featureFlags['v2.7.0_dublin'])
      setGateway(featureFlags['vgateway'])
      setWorkday(featureFlags['vgateway'])

      const newFeatureFlags: TFlag[] = []
      Object.entries(featureFlags).forEach(([key, value]) => {
        newFeatureFlags.push({
          name: key,
          isActive: Boolean(value)
        })
      })
      setFlags(newFeatureFlags)
      setIsFlagsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchFeatureFlagsSuccess])

  useEffect(() => {
    if (isFetchTxnGlobalSettingsSuccess) {
      setTxnGlobalSettings(txnGlobalSettings)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchTxnGlobalSettingsSuccess])

  /**
   * Initial loading guard rails when fetching for feature flags
   * This is needed to make sure no multiple calls will be made before and after the flags are fetched
   * This also prevents issues when the user navigates to a page that is dependent on these flags
   */
  if (isFlagsLoading)
    return (
      <div style={{ textAlign: 'center', margin: 18 }}>
        <Spin />
      </div>
    )

  return (
    <div data-testid='Base'>
      <Header />
      <div
        className='page-container'
        style={{ padding: isAnalytics ? '0px' : '18px' }}
      >
        <Outlet />
        <AppVersion />
      </div>
      {/* <UploadFileModal
        visible={isUploadFileModalVisible}
        setModalVisible={setIsUploadFileModalVisible}
        enableSourceData={
          companySettings && companySettings.enable_upload_files
        }
        enableImportAccrual={
          companySettings && companySettings.enable_import_accruals
        }
      /> */}
      <UploadFileModal />
      <ErrorModal
        visible={error.visible}
        status={error.status}
        message={error.message}
        reference={error.guid}
        onOk={() =>
          dispatch(
            setError({ visible: false, status: null, message: '', guid: '' })
          )
        }
      />
      <ForbiddenErrorModal
        visible={forbiddenError.visible}
        onOk={() =>
          dispatch(setForbiddenError({ visible: false, message: undefined }))
        }
      />
    </div>
  )
}

export default Base
