import { AUTH_TOKEN } from '../helpers/constants/Auth'
import jwt_decode from 'jwt-decode'
import RolesPermissions from '../types/RolesPermissions'
import ActionPermissions from '../helpers/constants/ActionPermissions'

export default function useRolesAndPermissions() {
  const checkPermission = (permissionId: number): boolean => {
    const authToken = localStorage.getItem(AUTH_TOKEN)

    if (authToken) {
      const jwtToken = window.atob(authToken)
      const rolesAndPermissions: RolesPermissions = jwt_decode(jwtToken)

      const permissions = rolesAndPermissions.action_permissions

      return permissions.includes(permissionId)
    }

    return false
  }

  const USER_HAS_TXN_EXPORT_PERMISSION: boolean = checkPermission(
    ActionPermissions.EXPORT_TRANSACTIONS
  )

  const USER_HAS_UPLOAD_FILE_PERMISSION: boolean = checkPermission(
    ActionPermissions.UPLOAD_SOURCE_DATA_TRANSACTIONS
  )

  const USER_HAS_ADD_LINE_PERMISSION: boolean = checkPermission(
    ActionPermissions.ADD_LINE_TRANSACTIONS
  )

  const USER_HAS_NUDGE_ALL_PERMISSION: boolean = checkPermission(
    ActionPermissions.NUDGE_ALL_TRANSACTIONS
  )

  const USER_HAS_NUDGE_SELECTED_PERMISSION: boolean = checkPermission(
    ActionPermissions.NUDGE_SELECTED_TRANSACTIONS
  )

  const USER_HAS_TXN_ATTACHMENTS_PERMISSION: boolean = checkPermission(
    ActionPermissions.ATTACHMENTS_TRANSACTIONS
  )

  const USER_HAS_CREATE_QJE_PERMISSION: boolean = checkPermission(
    ActionPermissions.CREATE_QJE
  )

  const USER_HAS_EXPORT_TXN_LINES_PERMISSION: boolean = checkPermission(
    ActionPermissions.EXPORT_LINES_TRANSACTIONS
  )

  const USER_HAS_TXN_COMMENTS_PERMISSION: boolean = checkPermission(
    ActionPermissions.COMMENTS_TRANSACTIONS
  )

  const USER_HAS_TXN_HISTORY_PERMISSION: boolean = checkPermission(
    ActionPermissions.HISTORY_TRANSACTIONS
  )

  const USER_HAS_TXN_LINE_PASSBACK_PERMISSION: boolean = checkPermission(
    ActionPermissions.PASSBACK_TRANSACTIONS
  )

  const USER_HAS_TXN_LINE_OVERRIDE_PERMISSION: boolean = checkPermission(
    ActionPermissions.OVERRIDE_TRANSACTIONS
  )

  const USER_HAS_TXN_LINE_COPY_LINE_PERMISSION: boolean = checkPermission(
    ActionPermissions.COPY_LINE_TRANSACTIONS
  )

  const USER_HAS_TXN_LINE_DELETE_COPIED_LINE_PERMISSION: boolean =
    checkPermission(ActionPermissions.DELETE_COPIED_LINE_TRANSACTIONS)

  const USER_HAS_TXN_LINE_HISTORY_PERMISSION: boolean = checkPermission(
    ActionPermissions.LINE_HISTORY_TRANSACTIONS
  )

  const USER_HAS_TXN_LINE_LOOKUP_PERMISSION: boolean = checkPermission(
    ActionPermissions.LINE_LOOKUP_TRANSACTIONS
  )

  const USER_HAS_UPDATE_COMMENT_PERMISSION: boolean = checkPermission(
    ActionPermissions.UPDATE_COMMENT
  )

  const USER_HAS_DELETE_COMMENT_PERMISSION: boolean = checkPermission(
    ActionPermissions.DELETE_COMMENT
  )

  const USER_HAS_ADD_COMMENT_PERMISSION: boolean = checkPermission(
    ActionPermissions.ADD_COMMENT
  )

  const USER_HAS_DOWNLOAD_ATTACHMENTS_PERMISSION: boolean = checkPermission(
    ActionPermissions.DOWNLOAD_ATTACHMENT
  )

  const USER_HAS_UPLOAD_IMPORTED_ACCRUALS_PERMISSION: boolean = checkPermission(
    ActionPermissions.UPLOAD_IMPORTED_ACCRUALS_TRANSACTIONS
  )

  const USER_HAS_VOID_HEADER_TXN_PERMISSION: boolean = checkPermission(
    ActionPermissions.VOID_HEADER_TRANSACTIONS
  )
  const USER_HAS_EXPORT_QJE_PERMISSION: boolean = checkPermission(
    ActionPermissions.EXPORT_QJES
  )
  const USER_HAS_POST_QJE_JE_PERMISSION: boolean = checkPermission(
    ActionPermissions.POST_QJE
  )

  const USER_HAS_LINE_CALCULATIONS_PERMISSION: boolean = checkPermission(
    ActionPermissions.LINE_CALCULATIONS
  )

  const USER_HAS_REVIEW_CENTER_TAB_PERMISSION: boolean = checkPermission(
    ActionPermissions.REVIEW_CENTER_DATA_REVIEW_CENTER
  )

  const USER_HAS_TASK_MANAGER_TAB_PERMISSION: boolean =
    checkPermission(ActionPermissions.VENDOR_TASKS) &&
    checkPermission(ActionPermissions.VENDOR_TASKS_SETTINGS) &&
    checkPermission(ActionPermissions.VENDOR_TASKS_INDEX) &&
    checkPermission(ActionPermissions.SOURCE_CHART_OF_ACCOUNTS_INDEX) &&
    checkPermission(ActionPermissions.SOURCE_VENDORS_INDEX) &&
    checkPermission(ActionPermissions.FORMS_INDEX)

  const USER_HAS_TRANSACTION_TAB_PERMISSION: boolean =
    checkPermission(ActionPermissions.VIEW_TRANSACTIONS) &&
    checkPermission(ActionPermissions.TRANSACTION_GLOBAL_SETTINGS)

  const USER_HAS_QJE_TAB_PERMISSION: boolean = checkPermission(
    ActionPermissions.QJE_VIEW_TRANSACTIONS
  )

  const USER_HAS_CREATE_QJE_FILTER_PERMISSION: boolean = checkPermission(
    ActionPermissions.CREATE_QJE_FILTER
  )

  const USER_HAS_REJECT_QJE_PERMISSION: boolean = checkPermission(
    ActionPermissions.QJE_REJECT_JE
  )

  const USER_HAS_CLOSE_PO_QJE_PERMISSION: boolean = checkPermission(
    ActionPermissions.QJE_REVIEW_PO
  )

  const USER_COPY_TASK_PERMISSION: boolean = checkPermission(
    ActionPermissions.VENDOR_TASKS_COPY_TASK
  )

  const USER_DELETE_TASK_PERMISSION: boolean = checkPermission(
    ActionPermissions.VENDOR_TASKS_DESTROY
  )

  const USER_HISTORY_TASK_PERMISSION: boolean = checkPermission(
    ActionPermissions.HISTORY_TASK_MANAGER
  )

  const USER_MASS_UPDATE_TASK_PERMISSION: boolean = checkPermission(
    ActionPermissions.VENDOR_TASKS_MASS_UPDATE
  )

  const USER_UPDATE_TASK_PERMISSION: boolean = checkPermission(
    ActionPermissions.VENDOR_TASKS_EDIT
  )

  const USER_HAS_REVIEW_CENTER_GET_ALL_FILTERS: boolean = checkPermission(
    ActionPermissions.REVIEW_CENTER_GET_ALL_FILTERS
  )

  const USER_HAS_REVIEW_CENTER_GET_FILTER: boolean = checkPermission(
    ActionPermissions.REVIEW_CENTER_GET_FILTER
  )

  const USER_HAS_REVIEW_CENTER_SAVE_FILTER: boolean = checkPermission(
    ActionPermissions.REVIEW_CENTER_SAVE_FILTER
  )

  const USER_HAS_REVIEW_CENTER_UPDATE_FILTER: boolean = checkPermission(
    ActionPermissions.REVIEW_CENTER_UPDATE_FILTER
  )

  const USER_HAS_REVIEW_CENTER_DELETE_FILTER: boolean = checkPermission(
    ActionPermissions.REVIEW_CENTER_DELETE_FILTER
  )

  const USER_HAS_REVIEWED_FLAG_QJE_PERMISSION: boolean = checkPermission(
    ActionPermissions.QJE_REVIEWED_FLAG
  )

  const USER_HAS_DOWNLOAD_TASKS_PERMISSION: boolean = checkPermission(
    ActionPermissions.EXPORT_TASK_MANAGER
  )

  const USER_HAS_VIEW_TRANSACTION_PERMISSION: boolean =
    checkPermission(ActionPermissions.ATTACHMENTS_TRANSACTIONS) &&
    checkPermission(ActionPermissions.FILTERING_TRANSACTIONS) &&
    checkPermission(ActionPermissions.VIEW_TRANSACTION_SETTINGS) &&
    checkPermission(ActionPermissions.VIEW_TRANSACTION) &&
    checkPermission(ActionPermissions.VIEW_TRANSACTION_LINE) &&
    checkPermission(ActionPermissions.VIEW_TRANSACTION_LINES) &&
    checkPermission(ActionPermissions.LIST_SOURCE_DATA) &&
    checkPermission(ActionPermissions.TRANSACTION_GLOBAL_SETTINGS) &&
    checkPermission(ActionPermissions.EMAIL_TRACKER_TRANSACTIONS) &&
    checkPermission(ActionPermissions.LIST_FILTERS)

  const USER_HAS_NO_QJE_PERMISSION: boolean = checkPermission(
    ActionPermissions.NO_QJE_TRANSACTION_LINES
  )

  const USER_HAS_CREATE_TM_FILTER_PERMISSION: boolean = checkPermission(
    ActionPermissions.CREATE_TM_FILTER
  )

  const USER_HAS_EDIT_TM_FILTER_PERMISSION: boolean = checkPermission(
    ActionPermissions.EDIT_TM_FILTER
  )

  const USER_HAS_DELETE_TM_FILTER_PERMISSION: boolean = checkPermission(
    ActionPermissions.DELETE_TM_FILTER
  )

  const USER_HAS_VIEW_TM_FILTERS_PERMISSION: boolean = checkPermission(
    ActionPermissions.VIEW_TM_FILTERS
  )

  const USER_HAS_GET_TM_FILTER_PERMISSION: boolean = checkPermission(
    ActionPermissions.GET_TM_FILTER
  )

  const USER_HAS_GET_TM_FILTER_FIELDS_PERMISSION: boolean = checkPermission(
    ActionPermissions.GET_TM_FILTER_FIELDS
  )

  const USER_HAS_VIEW_TXN_COLUMN_MANAGER_PERMISSION: boolean = checkPermission(
    ActionPermissions.VIEW_TRANSACTION_COLUMN_MANAGER
  )

  const USER_HAS_EDIT_TXN_COLUMN_MANAGER_PERMISSION: boolean = checkPermission(
    ActionPermissions.EDIT_TRANSACTION_COLUMN_MANAGER
  )

  const USER_HAS_DELETE_TXN_PERMISSION: boolean = checkPermission(
    ActionPermissions.DELETE_TRANSACTION
  )

  const USER_HAS_HISTORY_SHOW_PERMISSION: boolean = checkPermission(
    ActionPermissions.HISTORY_SHOW
  )

  const USER_HAS_ACCRUAL_RULES_MANAGER_SHOW: boolean = checkPermission(
    ActionPermissions.ACCRUAL_RULES_MANAGER_SHOW
  )
  const USER_HAS_SELF_SERVICE_USER_MANAGEMENT_PERMISSION: boolean =
    checkPermission(ActionPermissions.SELF_SERVICE_USER_MANAGEMENT)

  const USER_HAS_LOGIN_AUDIT_TRAIL_PERMISSION: boolean = checkPermission(
    ActionPermissions.LOGIN_AUDIT_TRAIL
  )

  const USER_HAS_GLOBAL_AA_PERMISSION: boolean = checkPermission(
    ActionPermissions.GLOBAL_AA
  )

  const USER_HAS_VENDOR_PERM_NOTES_AA_PERMISSION: boolean = checkPermission(
    ActionPermissions.VENDOR_PERM_NOTES_AA
  )

  const USER_HAS_UPDATE_ACCRUAL_MANAGER_AA_PERMISSION: boolean =
    checkPermission(ActionPermissions.UPDATE_ACCRUAL_MANAGER_AA)

  const USER_HAS_SCHEDULE_MANAGER_CREATE_PERMISSION: boolean = checkPermission(
    ActionPermissions.SCHEDULE_MANAGER_CREATE
  )

  const USER_HAS_SCHEDULE_MANAGER_GET_PERMISSION: boolean = checkPermission(
    ActionPermissions.SCHEDULE_MANAGER_GET
  )

  const USER_HAS_SCHEDULE_MANAGER_UPDATE_PERMISSION: boolean = checkPermission(
    ActionPermissions.SCHEDULE_MANAGER_UPDATE
  )

  const USER_HAS_SCHEDULE_MANAGER_DELETE_PERMISSION: boolean = checkPermission(
    ActionPermissions.SCHEDULE_MANAGER_DELETE
  )

  const USER_HAS_CONSOLIDATED_TRANSACTION_PERMISSION: boolean = checkPermission(
    ActionPermissions.VIEW_TRANSACTION_LINES_CONSOLIDATED
  )

  const USER_HAS_API_MANAGER_GET_PERMISSION: boolean = checkPermission(
    ActionPermissions.API_MANAGER_GET
  )

  const USER_HAS_API_MANAGER_CREATE_PERMISSION: boolean = checkPermission(
    ActionPermissions.API_MANAGER_GET
  )

  const USER_HAS_TRANSACTION_COLUMN_MANAGER_CONSOLIDATED: boolean =
    checkPermission(ActionPermissions.TRANSACTION_COLUMN_MANAGER_CONSOLIDATED)

  const USER_HAS_IMPORT_ACCRUAL_MANAGER_PERMISSION: boolean = checkPermission(
    ActionPermissions.IMPORT_ACCRUAL_MANAGER
  )

  const USER_HAS_REVIEW_CENTER_SETTINGS_PERMISSION: boolean = checkPermission(
    ActionPermissions.REVIEW_CENTER_SETTINGS
  )

  const USER_HAS_PERIOD_MANAGER_SETTINGS_PERMISSION: boolean = checkPermission(
    ActionPermissions.PERIOD_MANAGER_SETTINGS
  )

  const USER_HAS_INTEGRATION_LOGS_GET_PERMISSION: boolean = checkPermission(
    ActionPermissions.INTEGRATION_LOGS_GET
  )

  const USER_HAS_INTEGRATION_LOGS_EXPORT_PERMISSION: boolean = checkPermission(
    ActionPermissions.INTEGRATION_LOGS_EXPORT
  )

  return {
    USER_HAS_TXN_EXPORT_PERMISSION,
    USER_HAS_UPLOAD_FILE_PERMISSION,
    USER_HAS_ADD_LINE_PERMISSION,
    USER_HAS_NUDGE_ALL_PERMISSION,
    USER_HAS_NUDGE_SELECTED_PERMISSION,
    USER_HAS_TXN_ATTACHMENTS_PERMISSION,
    USER_HAS_CREATE_QJE_PERMISSION,
    USER_HAS_EXPORT_TXN_LINES_PERMISSION,
    USER_HAS_TXN_COMMENTS_PERMISSION,
    USER_HAS_TXN_HISTORY_PERMISSION,
    USER_HAS_TXN_LINE_PASSBACK_PERMISSION,
    USER_HAS_TXN_LINE_OVERRIDE_PERMISSION,
    USER_HAS_TXN_LINE_COPY_LINE_PERMISSION,
    USER_HAS_TXN_LINE_DELETE_COPIED_LINE_PERMISSION,
    USER_HAS_TXN_LINE_HISTORY_PERMISSION,
    USER_HAS_TXN_LINE_LOOKUP_PERMISSION,
    USER_HAS_UPDATE_COMMENT_PERMISSION,
    USER_HAS_DELETE_COMMENT_PERMISSION,
    USER_HAS_ADD_COMMENT_PERMISSION,
    USER_HAS_DOWNLOAD_ATTACHMENTS_PERMISSION,
    USER_HAS_UPLOAD_IMPORTED_ACCRUALS_PERMISSION,
    USER_HAS_VOID_HEADER_TXN_PERMISSION,
    USER_HAS_EXPORT_QJE_PERMISSION,
    USER_HAS_POST_QJE_JE_PERMISSION,
    USER_HAS_LINE_CALCULATIONS_PERMISSION,
    USER_HAS_REVIEW_CENTER_TAB_PERMISSION,
    USER_HAS_TASK_MANAGER_TAB_PERMISSION,
    USER_HAS_TRANSACTION_TAB_PERMISSION,
    USER_HAS_QJE_TAB_PERMISSION,
    USER_HAS_CREATE_QJE_FILTER_PERMISSION,
    USER_HAS_REJECT_QJE_PERMISSION,
    USER_HAS_CLOSE_PO_QJE_PERMISSION,
    USER_COPY_TASK_PERMISSION,
    USER_DELETE_TASK_PERMISSION,
    USER_HISTORY_TASK_PERMISSION,
    USER_MASS_UPDATE_TASK_PERMISSION,
    USER_UPDATE_TASK_PERMISSION,
    USER_HAS_REVIEW_CENTER_GET_ALL_FILTERS,
    USER_HAS_REVIEW_CENTER_GET_FILTER,
    USER_HAS_REVIEW_CENTER_SAVE_FILTER,
    USER_HAS_REVIEW_CENTER_UPDATE_FILTER,
    USER_HAS_REVIEW_CENTER_DELETE_FILTER,
    USER_HAS_REVIEWED_FLAG_QJE_PERMISSION,
    USER_HAS_DOWNLOAD_TASKS_PERMISSION,
    USER_HAS_VIEW_TRANSACTION_PERMISSION,
    USER_HAS_NO_QJE_PERMISSION,
    USER_HAS_CREATE_TM_FILTER_PERMISSION,
    USER_HAS_EDIT_TM_FILTER_PERMISSION,
    USER_HAS_DELETE_TM_FILTER_PERMISSION,
    USER_HAS_VIEW_TM_FILTERS_PERMISSION,
    USER_HAS_GET_TM_FILTER_PERMISSION,
    USER_HAS_GET_TM_FILTER_FIELDS_PERMISSION,
    USER_HAS_VIEW_TXN_COLUMN_MANAGER_PERMISSION,
    USER_HAS_EDIT_TXN_COLUMN_MANAGER_PERMISSION,
    USER_HAS_DELETE_TXN_PERMISSION,
    USER_HAS_HISTORY_SHOW_PERMISSION,
    USER_HAS_ACCRUAL_RULES_MANAGER_SHOW,
    USER_HAS_SELF_SERVICE_USER_MANAGEMENT_PERMISSION,
    USER_HAS_LOGIN_AUDIT_TRAIL_PERMISSION,
    USER_HAS_GLOBAL_AA_PERMISSION,
    USER_HAS_VENDOR_PERM_NOTES_AA_PERMISSION,
    USER_HAS_UPDATE_ACCRUAL_MANAGER_AA_PERMISSION,
    USER_HAS_SCHEDULE_MANAGER_CREATE_PERMISSION,
    USER_HAS_SCHEDULE_MANAGER_GET_PERMISSION,
    USER_HAS_SCHEDULE_MANAGER_UPDATE_PERMISSION,
    USER_HAS_SCHEDULE_MANAGER_DELETE_PERMISSION,
    USER_HAS_CONSOLIDATED_TRANSACTION_PERMISSION,
    USER_HAS_API_MANAGER_GET_PERMISSION,
    USER_HAS_API_MANAGER_CREATE_PERMISSION,
    USER_HAS_TRANSACTION_COLUMN_MANAGER_CONSOLIDATED,
    USER_HAS_IMPORT_ACCRUAL_MANAGER_PERMISSION,
    USER_HAS_REVIEW_CENTER_SETTINGS_PERMISSION,
    USER_HAS_PERIOD_MANAGER_SETTINGS_PERMISSION,
    USER_HAS_INTEGRATION_LOGS_GET_PERMISSION,
    USER_HAS_INTEGRATION_LOGS_EXPORT_PERMISSION
  }
}
