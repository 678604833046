/** antd imports */
import { Tag, Tooltip } from 'antd'
import { ColumnType } from 'antd/es/table'

/** moment imports */
import moment from 'moment'

/** react imports */
import { useMemo } from 'react'

/** enums imports */
import LogStatusEnum from '../enums/LogStatusEnum'

/** zustand store imports */
import useLogRecordModalStore from '../stores/useLogRecordModalStore'

/** types imports */
import { LogsTableRecord } from '../types/LogsTableRecord'

/** styles imports */
import style from './../styles/style.module.css'

// =================================================================
export default function useLogsColumns() {
  /** zustand store */
  const setRecord = useLogRecordModalStore((state) => state.setRecord)
  const setRecordMessage = useLogRecordModalStore(
    (state) => state.setRecordMessage
  )
  const setOpenModal = useLogRecordModalStore((state) => state.setOpenModal)

  /** memoized states */
  const columns = useMemo<ColumnType<LogsTableRecord>[]>(() => {
    return [
      {
        title: 'Timestamp (PST)',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (value, record) => {
          const date = new Date(record.created_at).toLocaleString('en-US', {
            timeZone: 'America/Los_Angeles'
          })

          const d = moment(date)

          return d.isValid() ? (
            <Tooltip title={d.format('YYYY-MM-DD hh:mm:ss')}>
              <span className={style.cell_text_sm}>
                {d.format('YYYY-MM-DD hh:mm:ss')}
              </span>
            </Tooltip>
          ) : (
            false
          )
        },
        sorter: true,
        showSorterTooltip: false,
        ellipsis: true,
        width: 155
      },
      {
        title: 'Request ID',
        dataIndex: 'request_id',
        key: 'request_id',
        sorter: true,
        showSorterTooltip: false,
        ellipsis: true,
        render: (value, record) => {
          return (
            <Tooltip title={record.request_id}>
              <span className={style.cell_text_sm}>{record.request_id}</span>
            </Tooltip>
          )
        }
      },
      {
        title: 'Batch ID',
        dataIndex: 'batch_id',
        key: 'batch_id',
        sorter: true,
        showSorterTooltip: false,
        ellipsis: true,
        render: (value, record) => {
          return (
            <Tooltip title={record.batch_id}>
              <span className={style.cell_text_sm}>{record.batch_id}</span>
            </Tooltip>
          )
        }
      },
      {
        title: 'ID',
        dataIndex: 'source_system_id',
        key: 'source_system_id',
        sorter: true,
        showSorterTooltip: false,
        ellipsis: true,
        render: (value, record) => {
          return (
            <Tooltip title={record.source_system_id}>
              <span className={style.cell_text_sm}>{record.source_system_id}</span>
            </Tooltip>
          )
        }
      },
      {
        title: 'API',
        dataIndex: 'api',
        key: 'api',
        sorter: true,
        showSorterTooltip: false,
        ellipsis: true,
        render: (value, record) => {
          return (
            <Tooltip title={record.api}>
              <span className={style.cell_text_sm}>{record.api}</span>
            </Tooltip>
          )
        },
        width: 180
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        sorter: true,
        showSorterTooltip: false,
        width: 90,
        render: (value, record) => {
          return (
            <Tooltip title={record.action}>
              <span className={style.cell_text_sm}>{record.action}</span>
            </Tooltip>
          )
        }
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        sorter: true,
        showSorterTooltip: false,
        width: 90,
        render: (value, record) => {
          return (
            <Tooltip title={record.type}>
              <span className={style.cell_text_sm}>{record.type}</span>
            </Tooltip>
          )
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 90,
        render: (value, record) => {
          const rec = JSON.stringify(
            {
              record: record.record,
              record_message: record.record_message
            },
            null,
            2
          )

          const views = {
            [LogStatusEnum.IN_PROGRESS]: (
              <Tag
                color='blue'
                onClick={() => {
                  setRecord(rec)
                  setRecordMessage(record.record_message)
                  setOpenModal(true)
                }}
                style={{ cursor: 'pointer' }}
              >
                <span className={style.cell_text_sm}>In Progress</span>
              </Tag>
            ),
            [LogStatusEnum.SUCCESS]: (
              <Tag
                color='green'
                onClick={() => {
                  setRecord(rec)
                  setRecordMessage(record.record_message)
                  setOpenModal(true)
                }}
                style={{ cursor: 'pointer' }}
              >
                <span className={style.cell_text_sm}>Success</span>
              </Tag>
            ),
            [LogStatusEnum.FAILED]: (
              <Tag
                color='red'
                onClick={() => {
                  setRecord(rec)
                  setRecordMessage(record.record_message)
                  setOpenModal(true)
                }}
                style={{ cursor: 'pointer' }}
              >
                <span className={style.cell_text_sm}>Failed</span>
              </Tag>
            )
          }

          return views[record.status]
        }
      }
    ]
  }, [setOpenModal, setRecord, setRecordMessage])

  return columns
}
